import {Component, OnInit, } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MainService} from '../../../../_shared/services/main.service';
import { Router, NavigationEnd, Event } from '@angular/router';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
    form: FormGroup;

    constructor(public  mainService: MainService ) {

    }

    ngOnInit() {
        this.form = new FormGroup({
            name: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.email, Validators.required]),
            country: new FormControl('', [Validators.required]),
            phone: new FormControl('', [Validators.required]),
            description: new FormControl('')
        });
        // this.checkId();

        // this.checkId();
    }


    onSubmit() {
        console.log('form submited!', this.form);
        this.form.reset();

    }


}
