import {Component, ElementRef, OnInit, ViewChild, OnDestroy , AfterViewInit} from '@angular/core';
import {MainService} from "../../../_shared/services/main.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, AfterViewInit, OnDestroy {
    singUp:string
    @ViewChild('getInTouch', {read:  ElementRef, static: false}) public getInTouch: ElementRef;
    private desrtoy$: Subject<void> = new Subject<void>();
    public closedMenu = true;


    constructor(public  mainService: MainService,
                private route: ActivatedRoute) {
    }
    ngOnInit() {
        this.singUp = localStorage.getItem('singUp');
        console.log('singUp', this.singUp);
        // localStorage.setItem('singUP','contact')
    }
    public ngOnDestroy(): void {
        this.desrtoy$.next();
        this.desrtoy$.complete();
    }

    public ngAfterViewInit(): void {
        setTimeout(()=>{
            this.route.fragment
                .pipe(takeUntil(this.desrtoy$))
                .subscribe(fragment => {
                    if (fragment === 'getInTouch') {

                        this.getInTouch.nativeElement.scrollIntoView(
                            {block: 'start',
                                inline: 'start',
                            }
                        );
                    }
                });
        },200)

    }

    isOpenedMenu(value) {
        return this.closedMenu = value;
    }


}
