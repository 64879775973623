import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {
  public closedMenu = true;

  constructor() {
  }

  ngOnInit() {
  }

  isOpenedMenu(value) {
    return this.closedMenu = value;
  }
}
