import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-sing-up',
  templateUrl: './home-sing-up.component.html',
  styleUrls: ['./home-sing-up.component.scss']
})
export class HomeSingUpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
