
import {Component, ElementRef, OnInit, ViewChild, Output,  OnDestroy , EventEmitter, AfterViewInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MainService} from "../../services/main.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";



@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements  OnInit, AfterViewInit, OnDestroy {
    @Output('isOpenedMenu') menuToggle = new EventEmitter();
    public isOpenedMenu = true;
    public headerLinks: any = [];
    public href = '';
    lang: string;
    path: any;
    @ViewChild('TOP', {read:  ElementRef, static: false}) public TOP: ElementRef;
    private desrtoy$: Subject<void> = new Subject<void>();

    constructor(public translate: TranslateService,
                public  mainService: MainService,
                private route: Router,
                private router: ActivatedRoute) {
        this.headerLinks = [
            {
                title: 'HEADER.HOME',
                link: '',
                id: ''
            },
            {
                title: 'HEADER.HOW_IT_WORKS',
                link: '/how-it-works',
                id: ''
            },
            {
                title: 'HEADER.ABOUT_US',
                link: '/about-us',
                id: ''
            },
            {
                title: 'HEADER.CONTACT_US',
                link: '/contact-us',
                id: ''
            },
            {
                title: 'HEADER.SING_UP',
                link: '/contact-us',
                id: 'singUp'
            }
        ];
    }

    ngOnInit() {
        this.lang = localStorage.getItem('lang');

    }
    isOpen(id): void {
        this.isOpenedMenu = true;
        if (id.id) {
            this.route.navigate(['/contact-us'], { fragment: 'getInTouch' });
            // if(window.innerWidth >= 900){
            //     window.scrollBy(0 , 650);
            // }
            // else {
            //     window.scrollBy(0 , 1200);
            // }
            // console.log(id);

        }
        // this.scrollTo('.footer');
    }
    changeLang(language): void {
        this.translate.use(language);
        localStorage.setItem('lang', language);
        this.lang = localStorage.getItem('lang');
    }

    public ngOnDestroy(): void {
        this.desrtoy$.next();
        this.desrtoy$.complete();
    }

    public ngAfterViewInit(): void {
        setTimeout(()=>{
            this.router.fragment
                .pipe(takeUntil(this.desrtoy$))
                .subscribe(fragment => {
                    if (fragment === 'TOP') {

                        this.TOP.nativeElement.scrollIntoView(
                            {block: 'start',
                                inline: 'start',
                            }
                        );
                    }
                });
        },200)

    }


    // scrollTo(className: string): void {
    //     console.log('clcik');
    //     const elementList = document.querySelectorAll( className);
    //     const element = elementList[0] as HTMLElement;
    //     element.scrollIntoView({ behavior: 'smooth' });
    // }

}
