import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DOCUMENT} from "@angular/common";
import {Router} from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    langfooter = 'en';

    constructor(private translate: TranslateService,
                private route: Router,
                @Inject(DOCUMENT) private document: Document) {
        translate.onLangChange.subscribe(lang => {
            this.langfooter = lang.lang;
            console.log(lang.lang);
            console.log(this.langfooter);
        });
    }

    ngOnInit() {
    }
    scrollTop():void{
        window.scrollBy(0 , -4000);
    }
    isOpen(item): void {
        localStorage.setItem('singUP','singUP')
        if(window.innerWidth >= 900){
            window.scrollBy(0 , -200);
        }
        else {
            window.scrollBy(0 , -750);
        }
        // this.scrollTo('.footer');
    }
    public goToGetInTouch() {
        this.route.navigate(['/contact-us'], { fragment: 'getInTouch' });
    }
    public goToTop(link:string) {
        this.route.navigate([`/${link}`], { fragment: 'TOP' });
    }


}
