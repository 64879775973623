import {Component, Inject, OnInit} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';
import {TranslateService} from "@ngx-translate/core";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-contact-top',
  templateUrl: './contact-top.component.html',
  styleUrls: ['./contact-top.component.scss']
})
export class ContactTopComponent implements OnInit {

  lang: string;
  options: AnimationOptions = {
    path: '/assets/animations/contact-us/top/data.json'
  };

  langfooter = 'en';

  constructor(private translate: TranslateService,
              @Inject(DOCUMENT) private document: Document) {
    translate.onLangChange.subscribe(lang => {
      this.langfooter = lang.lang;
      console.log(lang.lang);
      console.log(this.langfooter);
    });
  }

  ngOnInit() {
    this.langfooter = localStorage.getItem('lang');
    console.log('oninit')
    // this.translate.onLangChange.subscribe(lang => {
    //   this.langfooter = lang.lang;
    //   console.log(lang.lang);
    //   console.log(this.langfooter);
    // });
  }

  animationCreated(animationItem: any): void {
    animationItem.playSpeed = 0.7;
  }

}
