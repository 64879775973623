import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'montawa';
  lang:any;

  constructor(private translate: TranslateService,
              @Inject(DOCUMENT) private document: Document) {
    translate.onLangChange.subscribe(lang => {
      console.log(lang.lang);
      this.changeLangage(lang.lang);
    });
  }
  ngOnInit() {

  }
  changeLangage(lang: string) {

    let htmlTag = this.document.getElementsByTagName(
        "html"
    )[0] as HTMLHtmlElement;
    // htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
    // this.translateService.setDefaultLang(lang);
    this.translate.use(lang);
    this.changeCssFile(lang);
  }

  changeCssFile(lang: string) {
    let headTag = this.document.getElementsByTagName(
        "head"
    )[0] as HTMLHeadElement;
    let existingLink = this.document.getElementById(
        "langCss"
    ) as HTMLLinkElement;

    let bundleName = lang === "ar" ? "assets/styles-ar.css" : "assets/styles-en.css";

    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      let newLink = this.document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.type = "text/css";
      newLink.id = "langCss";
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }
}
